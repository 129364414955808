import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { TwitterScript } from '../../components/TwitterScript';
import { CommunityVideoPreview } from '../../components/CommunityViewPreview';
export const _frontmatter = {
  "commentId": "josh-comeau-interview",
  "image": "/images/interviews/josh-ama-preview.png",
  "date": "2021-03-05",
  "socialSharingImage": "/images/interviews/josh-ama-preview.png",
  "title": "How to Find a Blogging Niche You Love with Josh W Comeau",
  "summary": "Josh W Comeau has worked for Gatsby and Khan Academy, and recently struck out on his own to create his course CSS for JavaScript Developers. Learn how blogging led him to this niche and how Josh thinks about blogging.",
  "seoTitle": "How to Find a Blogging Niche You Love with Josh W Comeau",
  "seoDescription": "Josh W Comeau has worked for Gatsby and Khan Academy, and recently struck out on his own to create his course CSS for JavaScript Developers. Learn how blogging led him to this niche and how Josh thinks about blogging.",
  "tweet": "The fascinating story behind Josh Comeau's blog",
  "author": "monica",
  "interviewImage": "/images/interviews/josh-comeau.jpg",
  "interviewName": "Josh W Comeau",
  "interviewJob": "Creator of CSS for JavaScript Developers, Blogger at JoshWComeau.com",
  "tags": ["interview", "blogging"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src="/images/interviews/josh-ama-preview.png" alt="Josh W Comeau" style={{
      "maxWidth": "100%",
      "border": "1px solid #0D0F11",
      "display": "inline-block",
      "margin": "24px auto",
      "borderRadius": "8px"
    }} />
    <p>{`Josh W Comeau (`}<a href="https://twitter.com/JoshWComeau" target="_blank">{`@JoshWComeau`}</a>{`) has worked for Gatsby and Khan Academy, and recently struck out on his own to create his course `}<a href="https://css-for-js.dev/" target="_blank">{`CSS for JavaScript Developers`}</a>{`.`}</p>
    <p>{`I've been a fan of Josh's whimsical and creative `}<a href="https://joshwcomeau.com/" target="_blank">{`blog`}</a>{`, which `}<em>{`consistently`}</em>{` ranks at the top
of our list of the `}<Link to="/react-blogs/" mdxType="Link">{`best React blogs`}</Link>{` based on the kind of chatter his posts generate on Twitter.`}</p>
    <p>{`In this interview, Josh shares:`}</p>
    <ul>
      <li parentName="ul">{`What he tried earlier in his blogging career that didn't work out`}</li>
      <li parentName="ul">{`How he thinks about picking your niche`}</li>
      <li parentName="ul">{`Why Josh prefers visitors to his blog from Twitter over Hacker News`}</li>
      <li parentName="ul">{`How he's marketing his upcoming course, CSS for JavaScript Developers`}</li>
      <li parentName="ul">{`His advice for bloggers getting started in 2021`}</li>
    </ul>
    <p>{`Let's roll ✌️`}</p>
    <p><em parentName="p">{`This interview is an excerpt from the `}<Link to="/pro/" mdxType="Link">{`Blogging for Devs Community`}</Link>{` AMA session with Josh.`}</em></p>
    <h2>{`Hey Josh, Welcome! How'd you get started blogging? Anything you tried that didn't work out?`}</h2>
    <p>{`Well, thank you. Yeah, I'm excited to be here, I don't often get a chance to chat about blogging. So I'm excited to do this.`}</p>
    <p>{`Yeah, so I actually, I started blogging on Medium in 2014. So I've been doing
it for a while. And I went back to see because honestly,
who can remember? And it was interesting.`}</p>
    <p>{`I used find a problem I was currently working on, and pull
a little thing into its own article, which is kind of still what I do.`}</p>
    <p><strong parentName="p">{`But a lot of it was so niche that it was just not relevant to anyone.`}</strong></p>
    <p>{`Like I wrote a blog post about how to rotate a matrix in JavaScript. So if you
have like a two dimensional array, like how do I like rotate it so that like,
the first row becomes the first column, which like, actually, I went back, and
I looked at it, like, that's a kind of a cool blog post that has like, 27
views.`}</p>
    <p>{`There were `}<em parentName="p">{`a lot`}</em>{` of posts like that early on.`}</p>
    <img src="/images/interviews/josh-early-medium-posts.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p><em parentName="p"><strong parentName="em">{`Columns:`}</strong>{` Title, Views, Reads, Read Ratio, Fans`}</em></p>
    <p>{`Just looking back through the stats, it's like, this one has
130 views. This one has like 4000 and then the next one has back to like 17.`}</p>
    <p><strong parentName="p">{`So yeah, it took over 100 posts on Medium that I have totally forgotten about, before I created the blog I have today.`}</strong></p>
    <img src="/images/interviews/josh-blog-screenshot.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <h2>{`What are some of the main ways you promote your blog posts? Anything worked especially well for you?`}</h2>
    <p>{`Essentially, Twitter is my main source of letting people know about the things that I do.`}</p>
    <p>{`So whenever I publish a new post, I will publish it on Twitter. I'll also
generally like share it a couple of times. So maybe a couple days afterwards, and I'll try to like make the second tweet unique in
some way.`}</p>
    <p>{`For example, the first tweet might have a GIF of some element in it. And
the second one will have like an excerpt from a different part of it. This tends to do
better than the "In Case You Missed It" type of tweets.`}</p>
    <blockquote className="twitter-tweet" data-theme="dark" style={{
      "margin": "0 auto"
    }}><p lang="en" dir="ltr">💫 New blog post: “What the Heck, z-index??”<br /><br />If you&#39;ve ever dealt with a misbehaving z-index (they can be nefarious little buggers!), this article should help illuminate things 💡<br /><br />By understanding stacking contexts, we can do some pretty cool stuff ✨<a href="https://t.co/lrkxpDa9oL">https://t.co/lrkxpDa9oL</a></p>&mdash; Josh W. Comeau (@JoshWComeau) <a href="https://twitter.com/JoshWComeau/status/1363892488793112576?ref_src=twsrc%5Etfw">February 22, 2021</a></blockquote>
    <p>{`I also post myself on Hacker News.`}</p>
    <p>{`I originally stopped doing it because I found it wasn't worth the
aggravation, but then other people started doing it. They they're using
titles that I wouldn't have used. So now I do it anyway.`}</p>
    <p>{`But even if I'm not necessarily looking for Hacker News traffic, I at least
want to kind of control the tone of how it starts.`}</p>
    <p>{`And I post on Reddit sometimes. So like, if it's a
React post, I'll post it on `}<a href="http://reddit.com/r/reactjs" target="_blank">{`/r/reactjs`}</a>{`. Both of those things like Hacker News,
of course, is hit or miss. And I find that even if it does well, and I've had
probably six or seven posts now make it like pretty close to the top of Hacker
News, I'll get this big spike of traffic.`}</p>
    <p>{`But very, very little comes of the traffic from Hacker News
in terms of newsletter signups or in terms of like, people ever coming back
ever again.`}</p>
    <h2>{`Do you think that's because your niche is maybe pretty different than the typical Hacker News crowd?`}</h2>
    <p>{`Yeah, it makes sense that like the the percentage of
Hacker News that is interested in the things that I'm writing about is very,
very low.`}</p>
    <p>{`And so a lot of people that click through are just like, you know,
Rust developers that are curious, like, what is this thing I've never heard of?`}</p>
    <p>{`And of course, it's you know, it's fun to to get comments and people that are
in totally different areas, especially with my `}<a href="https://www.joshwcomeau.com/blog/hands-free-coding/" target="_blank">{`Hands-Free Coding post`}</a>{`.`}</p>
    <img src="/images/interviews/josh-hands-free-coding-post.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`Now, that one did well on Hacker News. And that one was actually really interesting, because I
heard from a lot of people that are in very different areas, like sometimes
they weren't even software developers, they were just like technicians of
various fields that had similar experiences.`}</p>
    <img src="/images/interviews/josh-hn-posts.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`So yeah, I think that Hacker News, feels good for vanity metrics.
Like, hey, I got like 30,000 hits today! And that's great. But
like very low relevance for me. I would
take 5,000 people that follow it from my Twitter and are like really engaged
with it over the 30,000 spike from Hacker News.`}</p>
    <p>{`Just because people coming from Twitter are more likely to be engaged with my content,
and it's actually just more beneficial in both directions.`}</p>
    <h2>{`How are you marketing your upcoming CSS course? Do you have a specific strategy for that?`}</h2>
    <p>{`So there are three main things I've been focusing on to let people know about my `}<a href="https://css-for-js.dev/" target="_blank">{`CSS Course`}</a>{`.`}</p>
    <p><strong parentName="p">{`First is the landing page for the course.`}</strong>{` I've added a couple little whimsical touches to make the page itself hopefully interesting. And then there's a an email signup form at the bottom. So people who are interested can sign up directly for that.`}</p>
    <p>{`And I'm going to try and send some like little goodies to that list of like preview content and early access to certain things. So that's one thing, landing page for that product.`}</p>
    <img src="/images/interviews/josh-course-lp.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p><strong parentName="p">{`Second is my blog.`}</strong>{` I want to be clear that I'm not just like writing fluff pieces to market my course. But the topics that I'm working on from my big list of ideas, I'm looking for ones that are would likely be useful for people who'd also like my CSS course.`}</p>
    <p>{`So like my most recent post on `}<a href="https://www.joshwcomeau.com/css/styled-components/" target="_blank">{`The styled-components Happy Path`}</a>{` is a good example, probably like people who like that post would also like my CSS course.`}</p>
    <img src="/images/interviews/josh-styled-components-post.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`Whereas like if I published a post about, like architecting React applications, maybe not so much, even though it's still front end development.`}</p>
    <p><strong parentName="p">{`Third is growing my email list.`}</strong>{` I've watched a couple of things now, like I saw Adam Wathan of Tailwind had a `}<a href="https://www.youtube.com/watch?v=ajrDxZRpP9M" target="_blank">{`talk at MicroConf`}</a>{` about how he launches and Wes Bos actually did `}<a href="https://www.youtube.com/watch?v=KBlOxBQrP9I&t=1s" target="_blank">{`a similar talk`}</a>{`. And both of them made the same point:`}</p>
    <p>{`Audience building is critical and mailing lists are king.`}</p>
    <p>{`For instance, if you have 20,000 people on a mailing list, you can expect much higher engagement, both in terms of like people who click on the link, but also people who follow through and buy the thing from a mailing list versus Twitter.`}</p>
    <h2>{`Do you have any specific tips for building an email list through blogging? What's worked for you?`}</h2>
    <p>{`Yes, so I've been trying to optimize traditional newsletter signups on my blog. And I've actually had a lot of fun with this.`}</p>
    <p>{`So I still have the typical section at the end of the blog post that lets you sign up for my newsletter.`}</p>
    <img src="/images/interviews/josh-newsletter-page.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`But what I decided to do I have this like 3D mascot of myself that I commissioned an artist in Spain to do. I've like I think I paid like $400 for it. It was pretty expensive. But I have, gotten like 10 times the value out of this little asset.`}</p>
    <p>{`So if you are a new visitor, you have to have read a certain amount of the blog post, my little 3d avatar kind of peeks out from the side.`}</p>
    <p>{`And a little speech bubble says, "Hey, can I tell you about something cool that I'm working on?" And you can say yes or no.`}</p>
    <img src="/images/interviews/josh-mascot.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`If you say yes, then he like throws a modal into the screen. And the modal tells you about whatever thing I want to tell you right now, I think it's just like, this year, I'm prioritizing my newsletter, you can expect to have X and Y content, here's like a preview, like you can view the most recent newsletter just to get a sense of what it's about.`}</p>
    <img src="/images/interviews/josh-popup.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p><strong parentName="p">{`And doing that has, I think, more than doubled the amount of people who sign up for my newsletter.`}</strong></p>
    <p>{`So I had a bunch of people that are like, I never sign up for newsletters, but that little guy like it, it got me.`}</p>
    <p>{`Another thing is I wrote a `}<a href="https://www.joshwcomeau.com/effective-portfolio/" target="_blank">{`book about portfolio sites`}</a>{`, specifically targeted to junior developers. It's free. So it's not sold anywhere. But the only way to get it is to join my mailing list.`}</p>
    <img src="/images/interviews/josh-portfolio-book.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`So I have three mailing lists: the blog, the course waitlist, and the list of people who downloaded my book. And I think the book makes up more than a third of my total signups.`}</p>
    <h2>{`Last question, what advice would you give other developers getting started blogging or creating content today?`}</h2>
    <p>{`Like, I mentioned that I had those 100 articles on Medium, but have very, very little traffic. And it can definitely be a long road until you start seeing like actual benefits from the work that you've been putting in.`}</p>
    <img src="/images/interviews/josh-medium-blog.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`But I think yeah, if you can focus on the things that you find really interesting, the amount of effort it takes is very much dependent on how much you care about the thing.`}</p>
    <p>{`Like if it's something that like, you just find really interesting and want to write about, then it's like no problem, like you can do that all day.`}</p>
    <p>{`Essentially what I would say is just follow what you're interested in. Don't worry too much if whatever you want to create already
exists.`}</p>
    <img src="/images/interviews/josh-blog-screenshot.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`And, the more niche you go, the better.`}</p>
    <p>{`That's why a lot of my posts don't do well on Hacker News, because they're very specific. For instance with my latest post,
not only do you have to be a React developer, but you have to be a React developer that uses styled components. Which is like a
subset of a subset.`}</p>
    <p>{`But if you go niche, you can produce something much, much more valuable for people who are interested in it.`}</p>
    <p>{`So yeah, I think that's the best advice I have. Just do things that interest you, and trust that it'll all kinda work out from there.`}</p>
    <h2>{`Big thanks to Josh for joining this session!`}</h2>
    <p>{`If you're a member of the `}<Link to="/pro/" mdxType="Link">{`Blogging for Devs Pro Community`}</Link>{`, you've got access to the full, one-hour video session! Click the video to check it out:`}</p>
    <CommunityVideoPreview url="https://community.bloggingfordevs.com/c/video-library/expert-session-with-josh-comeau-1h" src="/images/interviews/josh-ama-preview.png" alt="AMA Video with Josh Comeau" mdxType="CommunityVideoPreview" />
    <TwitterScript mdxType="TwitterScript" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      